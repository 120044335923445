//colors
$white: #ffffff;
$orange: #ff8a5d;
$text-color: #2d2f44;
$brown: #cd853f;
$grey: #a5adbc;
$grey-2: #99a2b3;
$grey-3: #f6f8fc;
$grey-4: #e2e6ed;
$grey-5: #c7cdd9;
$grey-6: #f1f3f7;
$light-grey: #fbfdfd;
$green: #3be9ab;
$green-2: #9ccc65;
$red: #ff5252;
$yellow: #f8c13c;
$yellow-2: #ffcd34;
$blue: #303f9f;
$purple: #ba68c8;
$light-green: #a5d6a7;
$pink: #ff1493;
$orange: #ff6600;
$orange-2: #ff8a5d;
$dark-green: #004e3e;
$light-blue: #0099cc;
$body-bg: #fbfdfd;
$dateRangeShadow: rgba(45, 47, 68, 0.12);
$avatarOverlay: rgba(0, 0, 0, 0.6);

//fonts
$font-regular: 'Mark Pro';
$font-medium: 'Mark Pro Medium';
$font-bold: 'Mark Pro Bold';
$font-light: 'Mark Pro Light';
$font-book: 'Mark Pro Book';

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 1200px,
  xl: 1300px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
);
$grid-gutter-width: 20px !default;
