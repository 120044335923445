@import './variables.scss';

.away-daterange {
  max-width: 664px;
  width: auto;
  padding: 0;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 $dateRangeShadow;

  .popover-body {
    padding: 0;
    padding-bottom: 23px;
  }

  .away-daterange-content {
    display: flex;
    flex-direction: column;

    .rdrDefinedRangesWrapper {
      display: none;
    }

    .rdrDateRangeWrapper {
      margin-bottom: 25px;
      flex: 1;

      .rdrDateDisplayWrapper {
        background-color: $white;
        border-bottom: 1px solid $grey-4;

        .rdrDateDisplay {
          max-width: 300px;
          background-color: $white;

          .rdrDateDisplayItem {
            box-shadow: none;
            border: none;
            border-radius: 1px;
            background-color: $grey-3;
            color: $grey-2;
            font-size: 16px;
            padding: 5px;
            text-align: left;

            input {
              line-height: 18px;
              height: 18px;
            }

            &.rdrDateDisplayItemActive {
              border: none;

              input {
                line-height: 18px;
                height: 18px;
              }
            }
          }
        }
      }

      .rdrMonthAndYearWrapper {
        padding-top: 0;
        padding-left: 30px;
        padding-right: 30px;
        position: absolute;
        width: 100%;
        left: 0;
        top: 54px;
        z-index: 2;

        .rdrMonthAndYearPickers {
          display: none;
        }

        .rdrNextPrevButton {
          display: block;
          width: 24px;
          height: 24px;
          margin: 0 0.833em;
          margin-top: -5px;
          padding: 0;
          border: 0;
          border-radius: 0;
          background: $white;
          outline: none;

          &:focus,
          &:hover {
            outline: none;
          }

          i {
            border: none;
            width: 0;
            height: 0;
          }

          &.rdrPprevButton::after,
          &.rdrNextButton::after {
            font-size: 20px;
            color: $orange-2;
          }

          &.rdrPprevButton::after {
            content: '<';
          }

          &.rdrNextButton::after {
            content: '>';
          }
        }
      }

      .rdrMonths {
        z-index: 1;

        .rdrMonth {
          &:first-of-type {
            padding-left: 40px;
          }

          &:last-of-type {
            padding-right: 40px;
          }

          .rdrMonthName {
            font-size: 21px;
            font-weight: 500;
            text-align: center;
            color: $text-color;
          }
        }
      }

      .rdrDayEndOfMonth .rdrInRange,
      .rdrDayEndOfMonth .rdrStartEdge,
      .rdrDayEndOfWeek .rdrInRange,
      .rdrDayEndOfWeek .rdrStartEdge {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .rdrDayStartOfMonth .rdrInRange,
      .rdrDayStartOfMonth .rdrEndEdge,
      .rdrDayStartOfWeek .rdrInRange,
      .rdrDayStartOfWeek .rdrEndEdge {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .rdrDayToday .rdrDayNumber span:after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        width: 18px;
        height: 2px;
        border-radius: 2px;
        background: $orange-2;
      }
    }

    .rdrCalendarWrapper {
      .rdrDayToday .rdrDayNumber span:after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        width: 18px;
        height: 2px;
        border-radius: 2px;
        background: $orange-2;
      }

      .rdrMonthAndYearWrapper {
        z-index: 2;

        .rdrMonthAndYearPickers {
          font-size: 16px;
        }

        .rdrNextPrevButton {
          display: block;
          width: 24px;
          height: 24px;
          margin: 0 0.833em;
          margin-top: -5px;
          padding: 0;
          border: 0;
          border-radius: 0;
          background: $white;
          outline: none;

          &:focus,
          &:hover {
            outline: none;
          }

          i {
            border: none;
            width: 0;
            height: 0;
          }

          &.rdrPprevButton::after,
          &.rdrNextButton::after {
            font-size: 20px;
            color: $orange-2;
          }

          &.rdrPprevButton::after {
            content: '<';
          }

          &.rdrNextButton::after {
            content: '>';
          }
        }
      }
    }

    .btn-primary {
      align-self: center;
    }
  }
}
