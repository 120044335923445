@import './variables.scss';
@import './fonts.scss';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';
@import './daterange.scss';
@import './colorpicker.scss';
@import './awayTableBootstrap.scss';
@import './awayTabs.scss';
@import './awayList.scss';
@import './awayModal.scss';
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

@import '~react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

// general style
body {
  font-family: $font-regular;
}
h2 {
  font-family: $font-regular;
  font-weight: 400;
}
h3 {
  font-size: 21px;
  font-family: $font-bold;
  font-weight: 500;
}

.away-search-form {
  margin-top: 0;
  margin-bottom: -1px;
  min-width: 300px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;

  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    width: 100%;
  }

  .away__indicators {
    margin-right: 0;

    .away__dropdown-indicator {
      display: none;
    }
  }

  .away__control {
    padding-left: 30px;
    min-height: 40px;
  }

  .form-group,
  .away-dropdown-form.version-2 {
    margin-bottom: 0;
  }

  .showSearch {
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 5;
    .fa {
      color: $grey-5;
    }

    &:focus,
    &:hover,
    &:active {
      .fa {
        cursor: pointer;
      }
    }

    &.disabled {
      &:focus,
      &:hover,
      &:active {
        .fa {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }
  }
}

.fa-small {
  padding: 5px;
  font-size: 20px;
}

.showPassword {
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 5;
  .fa {
    color: $grey;
  }

  &:focus,
  &:hover,
  &:active {
    .fa {
      cursor: pointer;
    }
  }

  &.disabled {
    opacity: 0.5;
    &:focus,
    &:hover,
    &:active {
      .fa {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}

//buttons
.btn {
  border-radius: 25px;
  font-size: 14px;
  font-family: $font-regular;
  padding: 13px;
  letter-spacing: 0.4px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    padding: 10px;
  }
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &.btn-primary {
    background-image: linear-gradient(-121deg, #ff7575 0%, #ffb230 100%);
    min-width: 165px;
    transition: all 0.15s ease-in-out;
    @media only screen and (max-width: 768px) {
      min-width: 135px;
    }
    &.btn-small {
      min-width: 100px;
    }
  }

  &.btn-primary,
  &.btn-secondary {
    &:not(:disabled):active:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &.btn-link {
    width: fit-content;
    text-decoration: none;
    color: $orange;
    background-color: transparent;

    &:hover,
    &:active {
      text-decoration: underline;
      outline: none;
      box-shadow: none;
      background-color: transparent;
      color: $orange;
    }
    &:not(:disabled):active:focus {
      outline: none;
      background-color: transparent;
      box-shadow: none;
      color: $orange;
    }

    &.btn-grey {
      color: $grey-5;
      background: transparent;
      &:focus,
      &:hover,
      &:active {
        background-color: transparent;
        color: $grey-5;
      }
    }
  }
}

.info-container {
  padding-top: 0;

  @media only screen and (max-width: 768px) {
    padding-top: 20px;
  }
}

.info-label {
  font-size: 12px;
  font-weight: 400;
  color: $grey;
  margin-bottom: 4px;
}

.info-text {
  font-size: 16px;
  font-weight: 400;
  color: $text-color;
  margin-bottom: 21px;
  a {
    text-decoration: none;
    color: inherit;
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    &:hover {
      cursor: pointer;
    }
  }
}

//card
.card-shadow {
  background-color: $white;
  border: 0;
  box-shadow: 0 2px 20px 0 rgba(34, 51, 73, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 20px 30px;
}

//date range picker
.popover {
  font-family: inherit;
  .rdrWeekDay {
    font-family: $font-book;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: $grey-5;
  }
  .rdrDayNumber {
    font-size: 14px;
  }
}

//inputs
.form-group {
  position: relative;
  margin-bottom: 40px;
  label {
    font-size: 16px;
    font-family: $font-book;
    color: $grey-2;
    position: absolute;
    top: 3px;
    left: 0;
    -webkit-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    cursor: text;
    z-index: 1;
    margin-bottom: 0;
    &.active {
      -webkit-transform: translateY(-110%);
      -ms-transform: translateY(-110%);
      transform: translateY(-110%);
      font-size: 12px;
    }
  }
  .invalid-feedback {
    font-family: $font-book;
    font-size: 12px;
    color: $red;
    font-weight: normal;
  }
  .select-error-text {
    font-family: $font-book;
    font-size: 12px;
    color: $red;
    font-weight: normal;
  }
  .form-control {
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 5;
    position: relative;
    border: none;
    border-bottom: 1px solid $grey-4;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 5px 0 5px 0;
    height: 38px;
    background-color: transparent;
    &:focus {
      outline: none;
      -webkit-box-shadow: 0 1px 0 0 $orange;
      box-shadow: 0 1px 0 0 $orange;
      border-bottom: 1px solid $orange;
    }
    &.is-invalid {
      border-color: $red;
    }
    &:disabled {
      cursor: not-allowed;
      color: $grey-2;
      border-bottom: 1px dotted $grey-2;
    }
  }
}

.form-group {
  textarea {
    &.form-control {
      height: auto;
      resize: none;
    }
  }
}
// remove icon from invalid inputs
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.force-focus {
  outline: none !important;
  -webkit-box-shadow: 0 1px 0 0 $orange !important;
  box-shadow: 0 1px 0 0 $orange !important;
  border-bottom: 1px solid $orange !important;
}
.custom-control-input:focus ~ .custom-control-indicator {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 3px #0275d8;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #0275d8;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-checkbox {
  margin-bottom: 40px;
  .form-check-input:checked {
    background-color: $orange !important;
    border-color: $orange;
  }
  .form-check-input:focus {
    box-shadow: none;
  }
}

//modal body background
.modal-backdrop {
  background-color: #181928;
}
.modal-backdrop.show {
  opacity: 0.7;
}

//away dropdown version 1
.away-dropdown {
  min-width: 180px;
  max-width: 180px;
  display: inline-block;
  margin-right: 20px;
  z-index: 1000;
  &:last-of-type {
    margin-right: 0;
  }
  .away__control,
  .awayColor__control {
    border-radius: 18px;
    background-color: $grey-3;
    border: 0;
    min-height: 30px;
    &.away__control--is-focused,
    &.awayColor__control--is-focused {
      box-shadow: 0 0 0 1px $orange;
    }
    &.away__control--is-disabled,
    &.awayColor__control--is-disabled {
      background-color: transparent;
    }
  }
  .away__value-container,
  .awayColor__value-container {
    line-height: 1;
  }
  .away__indicator-separator,
  .awayColor__indicator-separator {
    display: none;
  }
  .away__placeholder,
  .awayColor__placeholder {
    font-family: $font-book;
    font-weight: normal;
    font-size: 14px;
    color: $grey-2;
    position: relative;
    top: auto;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    text-transform: capitalize;
  }
  .away__single-value {
    margin-right: 0;
  }
  .away__single-value,
  .awayColor__single-value {
    font-family: $font-book;
    font-weight: normal;
    font-size: 14px;
    position: relative;
    top: auto;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    text-transform: capitalize;
  }
}
.away__menu {
  font-size: 12px;
  box-shadow: 0 2px 14px 0 rgba(12, 15, 19, 0.09);
  text-transform: capitalize;
  z-index: 2000;
  .away__menu-list {
    box-shadow: none;
  }
  .away__option--is-focused {
    background-color: transparent;
    position: relative;
    &:after {
      content: '';
      width: 3px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: $orange;
    }
  }
  .away__option--is-selected {
    background-color: transparent;
    color: $text-color;
    position: relative;
    &:after {
      content: '';
      width: 3px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: $orange;
    }
  }
}
.away__dropdown-indicator,
.awayColor__dropdown-indicator {
  padding: 4px;
}

//away dropdown version 2
.away-dropdown-form {
  &.version-2 {
    .away__control,
    .awayColor__control {
      border: 0;
      border-bottom: 1px solid $grey-4;
      border-radius: 0;
      .away__value-container,
      .awayColor__value-container {
        padding-left: 0;
      }
      &.away__control--is-focused,
      &.awayColor__control--is-focused {
        box-shadow: none;
        border-color: $orange;
      }
      &.away__control--is-disabled &.awayColor__control--is-disabled {
        background-color: transparent;
        cursor: not-allowed;
        pointer-events: all;
        border-bottom: 1px dotted $grey-2;
        .away__dropdown-indicator,
        &.awayColor__dropdown-indicator {
          svg {
            color: $grey-2;
          }
        }
      }
    }
    .away__menu {
      z-index: 1000;
      box-shadow: 0 2px 14px 0 rgba(12, 15, 19, 0.09);
      text-transform: capitalize;
      .away__menu-list {
        box-shadow: none;
      }
      .away__option--is-focused {
        background-color: transparent;
        position: relative;
        &:after {
          content: '';
          width: 3px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: $orange;
        }
      }
      .away__option--is-selected {
        background-color: transparent;
        color: $text-color;
        position: relative;
        &:after {
          content: '';
          width: 3px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: $orange;
        }
      }
    }
    .away__indicator-separator,
    .awayColor__indicator-separator {
      display: none;
    }
    .away__dropdown-indicator,
    .awayColor__dropdown-indicator {
      svg {
        color: $orange;
      }
    }
    .away__placeholder,
    .awayColor__placeholder {
      font-family: $font-book;
      font-weight: normal;
      color: $grey-2;
    }
    .away__single-value,
    .awayColor__single-value {
      font-family: $font-book;
      font-weight: normal;
      text-transform: capitalize;
      &.away__single-value--is-disabled,
      &.awayColor__single-value--is-disabled {
        color: $grey-2;
      }
    }

    .away__multi-value {
      background-color: $white;
      border: 1px solid $orange;
      .away__multi-value__remove {
        border-left: 1px solid $orange;

        &:hover {
          border-left: 1px solid $orange;
        }
      }
    }
  }
  &.no-capitalize {
    .away__menu {
      text-transform: none;
    }
    .away__single-value,
    .awayColor__single-value {
      text-transform: none;
    }
  }
}

//loader
.away-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.away-pagination {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  .pagination {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 0;
    align-self: flex-end;
    .page-item {
      .page-link {
        margin-left: 8px;
        margin-right: 8px;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid transparent;
        color: $orange;
        @media only screen and (max-width: 768px) {
          margin-left: 4px;
          margin-right: 4px;
          font-size: 14px;
        }
        &:hover {
          background-color: transparent;
          color: $text-color;
        }
      }

      &.active {
        .page-link {
          background-color: transparent;
          border-color: $orange;
          color: $orange;
        }
      }
    }
    .away-pagination-next {
      &.disabled {
        a {
          color: $grey;
        }
      }
    }
    .away-pagination-prev {
      &.disabled {
        a {
          color: $grey;
        }
      }
    }
  }
}

.text-capitalize {
  text-transform: capitalize;
}

// alerts

.alert-wrapper {
  position: fixed;
  left: 40px;
  width: auto;
  bottom: 40px;
  z-index: 1051;
  right: auto;
  top: auto;
  .alert {
    text-align: center;
    padding: 1rem;
    font-family: $font-bold;
    border: 0;
    margin-bottom: 0;
    min-width: 250px;
    box-shadow: 0 2px 20px 0 rgba(34, 51, 73, 0.05);
    &.alert-dismissible {
      padding-right: 2.5rem;
      .close {
        padding: 1rem;
        opacity: 1;
        text-shadow: none;
      }
    }
  }
  .alert-success {
    background-color: $green;
    color: $white;
  }
  .alert-danger {
    background-color: $red;
    color: $white;
  }
  .alert-info {
    background-color: $light-blue;
    color: $white;
  }
}
